<template>
  <div class="about">
    <h1>This is an Admin page</h1>
    hello
    {{ user }}
  </div>
</template>
<script lang="ts">
import getUser from '@/services/getUser';
import { defineComponent } from 'vue';

export default defineComponent({
  setup() {
    const user = getUser();
    return { user };
  },
});
</script>
