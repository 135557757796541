
import getUser from '@/services/getUser';
import { defineComponent } from 'vue';

export default defineComponent({
  setup() {
    const user = getUser();
    return { user };
  },
});
